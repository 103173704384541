<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!organization">
      <span class="loader"></span>
    </span>
    <div
      v-if="organization"
      class="elite-tabs-wrapper ViewOrganizationComponentTabs"
    >
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="global"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <organization-view-global
            :organization="organization"
            @onEditOrganization="editOrganization"
            @onDeleteOrganization="deleteOrganization"
          />
        </tab-pane>

        <tab-pane
          title="authorizations"
          id="3"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_PERMISSIONS)"
        >
          <span slot="title">
            <i class="ni ni-key-25"></i>
            {{ $t("ORGANIZATIONS.AUTHORIZATIONS") }}
          </span>
          <organization-view-authorizations :organization="organization" />
        </tab-pane>

        <tab-pane
          title="subscriptions"
          id="4"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SUBSCRIPTIONS)"
        >
          <span slot="title">
            <i class="fa fa-id-card"></i>
            {{ $t("COMMON.SUBSCRIPTIONS") }}
          </span>
          <organization-view-subscriptions :organization="organization" />
        </tab-pane>

        <tab-pane
          title="users"
          id="5"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
        >
          <span slot="title">
            <i class="ni ni-single-02"></i>
            {{ $t("COMMON.USERS") }}
          </span>
          <organization-view-users :organization="organization" />
        </tab-pane>

        <tab-pane
          title="roles"
          id="6"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ROLES)"
        >
          <span slot="title">
            <i class="ni ni-hat-3"></i>
            {{ $t("COMMON.ROLES") }}
          </span>
          <organization-view-roles :organization="organization" />
        </tab-pane>

        <tab-pane
          title="locations"
          id="7"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
        >
          <span slot="title">
            <i class="fa fa-id-card"></i>
            {{ $t("COMMON.LOCATIONS") }}
          </span>
          <organization-view-locations :filterOrganization="organization?.id" />
        </tab-pane>

        
        <tab-pane
          title="charges"
          id="8"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CHARGES)"
        >
          <span slot="title">
            <charge-icon :width="'20px'" class="custom-svg-icon" />
            {{ $t("CHARGES.CHARGES") }}
          </span>
          <div class="elite-tabs-wrapper-content">
          <div class="custom-header">
            <h3>{{ organization.name }} - {{ $t("CHARGES.CHARGES") }}</h3>
            <base-button
              class="elite-button add"
              type="button"
              @click="toggleModalState"
            >
              <span class="btn-inner--icon">
                <i class="far fa-edit"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("CHARGE_PACKAGES.EDIT_CHARGES") }}
              </span>
            </base-button>
          </div>
            <view-charge-package :charge-package-id="organization?.purchaseFees.id"/>
            <view-charge-package :charge-package-id="organization?.sellingFees.id"/>
          </div>
        </tab-pane>
        <tab-pane
          title="files"
          id="998"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_FILES)"
        >
          <span slot="title">
            <i class="ni ni-folder-17"></i>
            {{ $t("COMMON.FILES") }}
          </span>
          <div class="elite-tabs-wrapper-content">
            <list-file-component :object="getFilesObject()" />
          </div>
        </tab-pane>

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            <i class="fa fa-file"></i>
            {{ $t("COMMON.LOGS") }}
          </span>
          <organization-view-logs :organization="organization" />
        </tab-pane>
      </tabs>
    </div>

    <modal
      :show.sync="showSubscriptionAddModal"
      modal-classes="modal-secondary"
      size="lg"
    >
      <div class="col-12 text-center mb-2 mt-5">
        <h2>{{ $t("ORGANIZATIONS.SELECT_A_PACKAGE") }}</h2>
      </div>

      <form class="" @submit.prevent="generateSubscriptionInvoice">
        <base-input :label="$t('COMMON.PACKAGES')">
          <package-selector
            @packageChanged="
              (packageId) => (addSubscriptionPackageId = packageId)
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.package" />

        <div class="submit-wrapper">
          <base-button
            type="button"
            class="btn btn-sm elite-submit"
            native-type="submit"
            :disabled="loading"
          >
            <i class="fas fa-spinner fa-spin" v-if="loading"></i>
            {{ $t("ORGANIZATIONS.ADD_SUBSCRIPTION") }}
          </base-button>
        </div>
      </form>
    </modal>
    
  <modal :show="showModal" modal-classes="modal-secondary" size="lg">
    <organization-charge-packages-form
      v-if="showModal"
      :organization-id="organization?.id"
      :showModal="showModal"
      @onChangeModalState="toggleModalState"
      @needReload="needReload"
    />
  </modal>

  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import { cloneDeep } from "lodash";
import { Tabs, TabPane } from "@/components";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import PackageSelector from "@/components/PackageSelector.vue";
import ValidationError from "@/components/ValidationError.vue";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import OrganizationViewGlobal from "../partials/OrganizationViewGlobal.vue";
import OrganizationViewUsers from "../partials/OrganizationViewUsers.vue";
import OrganizationViewRoles from "../partials/OrganizationViewRoles.vue";
import OrganizationViewLogs from "../partials/OrganizationViewLogs.vue";
import OrganizationViewSubscriptions from "../partials/OrganizationViewSubscriptions.vue";
import OrganizationViewLocations from "../partials/OrganizationViewLocations.vue";
import OrganizationViewAuthorizations from "../partials/OrganizationViewAuthorizations.vue";
import ViewChargePackage from "../../ChargePackageManagement/components/ViewChargePackage.vue";
import OrganizationChargePackagesForm from "./OrganizationChargePackagesForm.vue";
import ChargeIcon from "@/components/Icons/ChargeIcon.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    OrganizationViewGlobal,
    OrganizationViewUsers,
    OrganizationViewRoles,
    OrganizationViewLogs,
    OrganizationViewSubscriptions,
    OrganizationViewLocations,
    OrganizationViewAuthorizations,
    ListFileComponent,
    PackageSelector,
    ValidationError,
    ViewChargePackage,
    ChargeIcon,
    OrganizationChargePackagesForm
  },

  mixins: [formMixin, requestErrorMixin],

  props: {
    organizationId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      showModal: false,
      organization: null,
      loading: false,
      showSubscriptionAddModal: false,
      addSubscriptionPackageId: null,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    needReload(organization) {
      this.$emit("needReload", organization);
    },
    toggleModalState() {
      //return;
      this.showModal = !this.showModal;
    },
    async get() {
      try {
        await this.$store.dispatch("organizations/get", this.organizationId);
        this.organization = this.$store.getters["organizations/organization"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async generateSubscriptionInvoice() {
      this.loading = true;
      try {
        const invoice = await this.$store.dispatch(
          "organizations/generateSubscriptionInvoice",
          {
            organizationId: this.organization.id,
            data: { package: this.addSubscriptionPackageId },
          }
        );

        this.$notify({
          type: "success",
          message: this.$t("ORGANIZATIONS.SUBSCRIPTION_INVOICE_ADDED"),
        });
        this.loading = false;

        this.$router.push(this.$objectViewRoute(invoice));
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
        this.setApiValidation(error.response.data.errors);
      }
    },

    getFilesObject() {
      return {
        ...cloneDeep(this.organization),
        organization: cloneDeep(this.organization),
      };
    },
    editOrganization(organization) {
      this.$emit("onEditOrganization", organization);
    },
    deleteOrganization(organization) {
      this.$emit("onDeleteOrganization", organization);
    },
  },
};
</script>
